import React, { useState } from 'react';
import axios from 'axios';
import './SellerForm.css';
import { sellerTemplate, buyerTemplate, otherTemplate } from '../../whatsapp_temp';
import states from '../../states.json'

const SellerForm = ({ onAddSeller }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailID, setEmailID] = useState('');
    const [website, setWebsite] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [notes, setNotes] = useState('');
    const todayDate = new Date().toISOString().split('T')[0];
    const [lastMailShared, setLastMailShared] = useState(todayDate);
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [customMessage, setCustomMessage] = useState({});
    const [customMessageVisible, setCustomMessageVisible] = useState({});
    const [templates] = useState(['Seller', 'Buyer', 'Other']); // Added template options

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Add the seller to the server
            const response = await axios.post('http://localhost:5001/sellers', {
                firstName,
                lastName,
                companyName,
                phoneNumber,
                emailID,
                website,
                city,
                state,
                notes,
                lastMailShared,
                selectedTemplate
            });
            console.log('Seller added:', response.data);
            onAddSeller(response.data);

            setSuccessMessage('Seller added successfully!');

             // Use the appropriate WhatsApp template
             let message = '';
             if (selectedTemplate === 'Seller') {
                 message = sellerTemplate; //.replace('${firstName}', firstName).replace('${lastName}', lastName).replace('${companyName}', companyName);
             } else if (selectedTemplate === 'Buyer') {
                 message = buyerTemplate; //.replace('${firstName}', firstName);
             } else if (selectedTemplate === 'Other') {
                 message = otherTemplate;
             }

            // Construct WhatsApp URL
            const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

            // Ensure the window.open call is made directly as part of the user action (onSubmit)
            setTimeout(() => {
                const whatsappWindow = window.open(whatsappURL, '_blank');
                if (!whatsappWindow) {
                    alert('Please allow popups for this site to open WhatsApp.');
                }
            }, 500); // Timeout to ensure the message is constructed first

            // Clear form fields after submission
            setFirstName('');
            setLastName('');
            setCompanyName('');
            setPhoneNumber('');
            setEmailID('');
            setWebsite('');
            setCity('');
            setState('');
            setNotes('');
            setLastMailShared(todayDate);
            setSelectedTemplate('');
            setCustomMessage({});
        } catch (error) {
            console.error('Error adding seller:', error.response ? error.response.data : error.message);
            setSuccessMessage('Seller is added successfully & Email is shared');
        }
    };

    const handleClear = () => {
        setFirstName('');
        setLastName('');
        setCompanyName('');
        setPhoneNumber('');
        setEmailID('');
        setWebsite('');
        setCity('');
        setState('');
        setNotes('');
        setLastMailShared(todayDate);
        setSelectedTemplate('');
        setSuccessMessage('');
    };

    const handleTemplateSelect = (selectedTemplateIndex) => {
        setSelectedTemplate(templates[selectedTemplateIndex]);
        const isCustomMessage = selectedTemplateIndex === '2'; // 'Other' template index
        setCustomMessageVisible({ ...customMessageVisible, [selectedTemplateIndex]: isCustomMessage });
    };

    return (
        <div className='sellerForm'>
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                              type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First Name"
                            
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last Name"
                            
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="companyName">Company Name</label>
                        <input
                            type="text"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Company Name"
                            
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                            type="text"
                            value={phoneNumber.startsWith("91") ? phoneNumber : "91" + phoneNumber}
                            onChange={(e) => {
                                const input = e.target.value;
                                // Prevent removal of "91" and keep numbers only
                                if (input.startsWith("91") && /^\d*$/.test(input)) {
                                    setPhoneNumber(input);
                                } else if (/^\d*$/.test(input.slice(2))) {
                                    setPhoneNumber("91" + input.slice(2));
                                }
                            }}
                            placeholder="Enter phone number"
                            
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailID">Email ID</label>
                        <input
                            type="email"
                            value={emailID}
                            onChange={(e) => setEmailID(e.target.value)}
                            placeholder="Email ID"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="website">Website</label>
                        <input
                            type="url"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                            placeholder="Website"
                            
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            placeholder="City"
                            
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="state">State</label>
                        <select
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            
                        >
                            <option value="">Select State</option>
                            {states.map((state, index) => (
                            <option key={index} value={state}>
                                {state}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="Date">Mail Shared on</label>
                        <input
                            type="date"
                            value={lastMailShared}
                            onChange={(e) => setLastMailShared(e.target.value)}
                            placeholder="Last Mail Shared"
                            
                        />
                    </div>

                    <div className="template-options">
                        <p>Select a template:</p>
                        <select
                            onChange={(e) => handleTemplateSelect(e.target.value)}
                        >
                            <option value="">Select a Whatsapp template</option>
                            {templates.map((template, index) => (
                                <option key={index} value={index}>
                                    {template}
                                </option>
                            ))}
                        </select>

                        {customMessageVisible[selectedTemplate] && (
                            <>
                                <textarea
                                    placeholder="Enter your custom message"
                                    value={customMessage[selectedTemplate] || ""}
                                    onChange={(e) =>
                                        setCustomMessage((prev) => ({
                                            ...prev,
                                            [selectedTemplate]: e.target.value,
                                        }))
                                    }
                                ></textarea>
                            </>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="notes">Notes</label>
                        <input
                            type="text"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            placeholder="Notes"
                            
                        />
                    </div>
                </div>
                <div className="submit-btn">
                    <button type="submit">Add Seller</button>
                    <button type="button" onClick={handleClear}>Clear</button>
                </div>
                {successMessage && (
                    <div className="success-message">{successMessage}</div>
                )}
            </form>
        </div>
    );
};

export default SellerForm;
