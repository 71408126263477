// whatsappTemplates.js

const sellerTemplate = `*Vidyutcloud (vidyutcloud.com)* - B2B Industrial Dedicated Electrical Ecommerce website for Transformers, Motors, Generators, Solar, Cables.
We help buyers connect with suitable manufacturers for their requirements.

*Here’s why you should try Vidyut Cloud:*

• Dedicated leads tailored to your product.
• Negotiate prices and finalize orders online.
• Low subscription charges.
• Technical support from a team with 10+ years of experience.

Thanks & Regards,
--
Niharika Deshpande
Product Sales Manager
8169517831, 
info@vidyutcloud.com
Vidyut Cloud, www.vidyutcloud.com
GST 27AAWFV4391F1ZH`;

const buyerTemplate = `Vidyutcloud - B2B Industrial Dedicated Electrical E-commerce website for Transformer, Motor, Generator, Solar, Cables.

*Vidyut Cloud (www.vidyutcloud.com)* helping buyers to connect with suitable manufacturer for their requirements.

*Here’s why you should try Vidyut Cloud:*

•⁠ Free access to a wide range of electrical products and services.
•⁠ Post your requirements and receive quotations directly from manufacturers.
•⁠ Negotiate prices and finalize orders online.
•⁠ Join Vidyut Cloud now for a hassle-free buying experience.

Thanks & Regards,

Niharika Deshpande
Product Sales Manager
8169517831,
info@vidyutcloud.com
Vidyut Cloud, www.vidyutcloud.com
GST 27AAWFV4391F1ZH`;

// Export templates
module.exports = {
    sellerTemplate,
    buyerTemplate
};
