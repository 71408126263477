// src/NavBar/NavBar.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../App'; // Ensure this path is correct
import './NavBar.css';

const NavBar = () => {
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

    const handleLogout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated'); // Remove from local storage
    };

    return (
        <nav>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/sellers">Sellers</Link>
                </li>
                <li>
                    <Link to="/enquiries">Enquiries</Link>
                </li>
                <li>
                    <Link to="/buyers">Buyers</Link>
                </li>
                <li>
                    {isAuthenticated ? (
                        <button onClick={handleLogout}>Log Out</button>
                    ) : (
                        <Link to="/login">Log In</Link>
                    )}
                </li>
            </ul>
        </nav>
    );
};

export default NavBar;
