import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './EnquiryList.css';
import { buyerTemplate, otherTemplate } from '../../whatsapp_temp';

const EnquiryList = () => {
    const [enquiries, setEnquiries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [editEnquiry, setEditEnquiry] = useState({});
    const [showTemplates, setShowTemplates] = useState({});
    const [customMessage, setCustomMessage] = useState({});
    const [customMessageVisible, setCustomMessageVisible] = useState({});

    useEffect(() => {
        const fetchEnquiries = async () => {
            try {
                const response = await axios.get('http://localhost:5001/enquiries');
                const sortedEnquiries = response.data.sort((a, b) => a.enquiryID.localeCompare(b.enquiryID));
                setEnquiries(sortedEnquiries);
            } catch (error) {
                console.error('Error fetching enquiries:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchEnquiries();
    }, []);

    if (loading) {
        return <div>Loading enquiries...</div>;
    }

    const handleEditClick = (enquiry) => {
        setEditEnquiry(enquiry);
        setEditMode(true);
    };

    // Function to handle form input changes
    const handleInputChange = (e) => {
        setEditEnquiry({
            ...editEnquiry,
            [e.target.name]: e.target.value
        });
    };

    const saveChanges = async () => {
        console.log('Updating enquiry with data:', editEnquiry);  // Log the data being sent
        try {
            const response = await axios.put(`http://localhost:5001/enquiries/${editEnquiry.id}`, editEnquiry, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                },
            });
            console.log('Enquiry updated:', response.data);
            setEnquiries((prevEnquiries) =>
                prevEnquiries.map((s) => (s.id === response.data.id ? response.data : s))
            );
            setEditMode(false);
        } catch (error) {
            console.error('Error updating enquiry:', error);
            console.error('Error response:', error.response);
        }
        
    };
    

    const sendWhatsapp = (enquiry, template) => {
        const whatsappMessage = template.replace("{buyerName}", enquiry.buyerName);
        const whatsappUrl = `https://wa.me/${enquiry.phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappUrl, '_blank');
    };

    const templates = [buyerTemplate, otherTemplate];

    const handleTemplateSelect = (enquiry, templateIndex) => {
        if (templateIndex === 1) {
            setCustomMessageVisible((prev) => ({
                ...prev,
                [enquiry.id]: true,
            }));
        } else {
            setCustomMessageVisible((prev) => ({
                ...prev,
                [enquiry.id]: false,
            }));
            const template = templates[templateIndex];
            sendWhatsapp(enquiry, template);
        }
    };

    const toggleTemplates = (enquiryId) => {
        setShowTemplates((prev) => ({
            ...prev,
            [enquiryId]: !prev[enquiryId],
        }));
    };

    const deleteEnquiry = async (enquiryId) => {
        if (window.confirm("Are you sure you want to delete this enquiry?")) {
            try {
                const response = await axios.delete(`http://localhost:5001/enquiries/${enquiryId}`);
                console.log('Enquiry deleted:', response.data);
                setEnquiries((prevEnquiries) => prevEnquiries.filter((enquiry) => enquiry.id !== enquiryId));
            } catch (error) {
                console.error('Error deleting enquiry:', error);
                alert('Error deleting enquiry');
            }
        }
    };
    const sendEmail = async (enquiry) => {
        try {
            const response = await axios.post(`http://localhost:5001/enquiries/${enquiry.id}/send-email`, {
                emailID: enquiry.emailID,
                subject: "Vidyutcloud - E-commerce Website For Electrical Products",
                enquiryInfo: {
                    firstName: enquiry.firstName,
                    lastName: enquiry.lastName,
                    companyName: enquiry.companyName,
                    phoneNumber: enquiry.phoneNumber,
                    emailID: enquiry.emailID,
                    city: enquiry.city,
                    state: enquiry.state,
                },
            });
            console.log('Email sent:', response.data);
            alert(`Email sent to ${enquiry.emailID}`);
    
            const updatedEnquiry = {
                ...enquiry,
                lastMailShared: new Date().toISOString(),
            };
    
            setEnquiries((prevEnquiries) => prevEnquiries.map((s) => (s.id === updatedEnquiry.id ? updatedEnquiry : s)));
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email');
        }
    };

    const states = [
        'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat',
        'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh',
        'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
        'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh',
        'Uttarakhand', 'West Bengal', 'Delhi', 'Chandigarh', 'Puducherry', 'Ladakh',
        'Jammu and Kashmir', 'Lakshadweep',
    ];

    const statusOptions = [
        'Pending', 'Closed', 'Cancelled', 'Approved'
    ];

    
    return (
        <div>
            <h2>Enquiry List</h2>
            {editMode ? (
                <div className="edit-form">
                    <h3>Edit Enquiry</h3>
                    <label>
                        Buyer Name:
                        <input
                            type="text"
                            name="buyerName"
                            value={editEnquiry.buyerName}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Description:
                        <input
                            type="text"
                            name="description"
                            value={editEnquiry.description}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Product Type:
                        <input
                            type="text"
                            name="productType"
                            value={editEnquiry.productType}
                            onChange={handleInputChange}
                        />
                    </label>
                    
                    <label htmlFor="phoneNumber">Phone Number:
                        <input
                            type="text"
                            name="phoneNumber"
                            value={editEnquiry.phoneNumber}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label htmlFor="emailID">Email ID:
                        <input
                            type="text"
                            name="emailID"
                            value={editEnquiry.emailID}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label htmlFor="city">City:
                        <input
                            type="text"
                            name="city"
                            value={editEnquiry.city}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label htmlFor="state">State:
                        <select
                            name="state"
                            value={editEnquiry.state}
                            onChange={handleInputChange}
                        >
                            <option value="">Select State</option>
                            {states.map((state) => (
                                <option key={state} value={state}>{state}</option>
                            ))}
                        </select>
                    </label>
                    <label htmlFor="status">Status:
                        <select
                            name="status"
                            value={editEnquiry.status}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Status</option>
                            {statusOptions.map((status) => (
                                <option key={status} value={status}>{status}</option>
                            ))}
                        </select>
                    </label>

                    <div className='submit-btn'>
                            <button type="submit" onClick={saveChanges}>Update Enquiry</button>
                            <button type="button" onClick={() => setEditMode(false)}>Cancel</button>
                        </div>
                </div>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Sl. No</th>
                            <th>Enquiry ID</th>
                            <th>Date</th>
                            <th>Buyer Name</th>
                            <th>Description</th>
                            <th>Product Type</th>
                            <th>Email ID</th>
                            <th>Phone Number</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Status</th>
                            <th>Last Mail Shared</th>
                            <th>Edit</th>
                            <th>Email</th>
                            <th>Delete</th>
                            <th>WhatsApp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {enquiries.map((enquiry, index) => (
                            <tr key={enquiry.id}>
                                <td>{index + 1}</td>
                                <td>{enquiry.enquiryID}</td>
                                <td>{new Date(enquiry.date).toLocaleDateString()}</td>
                                <td>{enquiry.buyerName}</td>
                                <td>{enquiry.description}</td>
                                <td>{enquiry.productType}</td>
                                <td>{enquiry.emailID}</td>
                                <td>{enquiry.phoneNumber}</td>
                                <td>{enquiry.city}</td>
                                <td>{enquiry.state}</td>
                                <td>{enquiry.status}</td>
                                <td>{new Date(enquiry.lastMailShared).toLocaleDateString()}</td>
                                <td>
                                    <button onClick={() => handleEditClick(enquiry)}>Edit</button>
                                </td>
                                <td>
                                    <button onClick={() => sendEmail(enquiry)}>Email</button>
                                </td>
                                <td>
                                    <button onClick={() => deleteEnquiry(enquiry.id)}>Delete</button>
                                </td>
                                <td>
                                    <button onClick={() => toggleTemplates(enquiry.id)}>WhatsApp</button>
                                    {showTemplates[enquiry.id] && (
                                        <div className="template-options">
                                            <p>Select a template:</p>
                                            {templates.map((template, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => handleTemplateSelect(enquiry, index)}>
                                                    {index === 0 ? "Buyer" : "Other"}
                                                </button>
                                            ))}
                                            {customMessageVisible[enquiry.id] && (
                                                <>
                                                    <textarea
                                                        placeholder="Enter your custom message"
                                                        value={customMessage[enquiry.id] || ""}
                                                        onChange={(e) => setCustomMessage((prev) => ({
                                                            ...prev,
                                                            [enquiry.id]: e.target.value,
                                                        }))}/>
                                                    <button onClick={() => sendWhatsapp(enquiry, customMessage[enquiry.id])}>
                                                        Send Custom Message
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default EnquiryList;
