import React, { useState } from 'react';
import axios from 'axios';
import './EnquiryForm.css';

const EnquiryForm = ({ onAddEnquiry, sellerList, enquiries = [] }) => {
    // State for enquiry details
    const [enquiryID, setEnquiryID] = useState('');
    const [date, setDate] = useState('');
    const [buyerName, setBuyerName] = useState('');
    const [description, setDescription] = useState('');
    const [productType, setProductType] = useState('');
    const [emailID, setEmailID] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [status, setStatus] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // State for email form
    const [emailFormVisible, setEmailFormVisible] = useState(false);
    const [selectedSeller, setSelectedSeller] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [attachment, setAttachment] = useState(null);

    // Dropdown options
    const states = [
        'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat',
        'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh',
        'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
        'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh',
        'Uttarakhand', 'West Bengal', 'Delhi', 'Chandigarh', 'Puducherry', 'Ladakh',
        'Jammu and Kashmir', 'Lakshadweep',
    ];

    const statusOptions = ['Pending', 'Closed', 'Cancelled', 'Approved'];
    const products = ['Transformer', 'Generator', 'Motor', 'Solar'];

    // Add enquiry to the database
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5001/enquiries', {
                enquiryID,
                date,
                buyerName,
                description,
                productType,
                emailID,
                phoneNumber,
                city,
                state,
                status,
            });
            console.log('Enquiry added:', response.data);
            setSuccessMessage('Enquiry added successfully!');
            setErrorMessage('');
            onAddEnquiry(response.data); // Pass the new enquiry to the parent component
            clearFormFields();
        } catch (error) {
            setErrorMessage('Error adding enquiry. Please try again.');
            setSuccessMessage('');
        }
    };
            
        // Clear form fields
        const clearFormFields = () => {
        setEnquiryID('');
        setDate('');
        setBuyerName('');
        setDescription('');
        setProductType('');
        setEmailID('');
        setPhoneNumber('');
        setCity('');
        setState('');
        setStatus('');
    }; 

        const handleSendEnquiry = () => {
            setEmailFormVisible(true); // Show the email form
        };
        
        const handleEnquirySelection = (e) => {
            const selectedID = e.target.value;
            setEnquiryID(selectedID);
        
            // Find the selected enquiry details
            const selectedEnquiry = enquiries.find((enquiry) => enquiry.enquiryID === selectedID);
        
            if (selectedEnquiry) {
                const predefinedSubject = `Enquiry for ${selectedEnquiry.productType} - ${selectedEnquiry.description}`;
                setSubject(predefinedSubject);
                setBody(`
        Dear ${selectedEnquiry.buyerName},
        
        Thank you for reaching out. We have noted your enquiry details:
        
        - Enquiry ID: ${selectedEnquiry.enquiryID}
        - Product Type: ${selectedEnquiry.productType}
        - Description: ${selectedEnquiry.description}
        - City: ${selectedEnquiry.city}
        - State: ${selectedEnquiry.state}
        - Contact: ${selectedEnquiry.phoneNumber}
        - Email: ${selectedEnquiry.emailID}
        
        We will get back to you shortly with more details.
        
        Best regards,  
        Your Company Name
                `);
            }
        };
        
        

    const handleFileChange = (e) => {
        setAttachment(e.target.files[0]);
    };

    const handleSendEmail = () => {
        console.log('Email sent to:', selectedSeller);
        console.log('Subject:', subject);
        console.log('Body:', body);
        console.log('Attachment:', attachment);

        setEmailFormVisible(false);
        setSubject('');
        setBody('');
        setAttachment(null);
    };

    return (
        <div className='enquiryForm'>
            <form className='enqFrm' onSubmit={handleSubmit}>
                <div className="form-row">
                    {/* Form Fields for Enquiry */}
                    <div className="form-group">
                        <label htmlFor="enquiryID">Enquiry ID</label>
                        <input
                            type="text"
                            value={enquiryID}
                            onChange={(e) => setEnquiryID(e.target.value)}
                            placeholder="Enquiry ID"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="date">Date</label>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="productType">Product Type</label>
                        <select
                            value={productType}
                            onChange={(e) => setProductType(e.target.value)}
                            required
                        >
                            <option value="">Select a Product Type</option>
                            {products.map((productType, index) => (
                                <option key={index} value={productType}>{productType}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Description"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="buyerName">Buyer Name</label>
                        <input
                            type="text"
                            value={buyerName}
                            onChange={(e) => setBuyerName(e.target.value)}
                            placeholder="Buyer Name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                            type="text"
                            value={phoneNumber.startsWith("91") ? phoneNumber : "91" + phoneNumber}
                            onChange={(e) => {
                                const input = e.target.value;
                                if (input.startsWith("91") && /^\d*$/.test(input)) {
                                    setPhoneNumber(input);
                                } else if (/^\d*$/.test(input.slice(2))) {
                                    setPhoneNumber("91" + input.slice(2));
                                }
                            }}
                            placeholder="Phone Number"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailID">Email ID</label>
                        <input
                            type="email"
                            value={emailID}
                            onChange={(e) => setEmailID(e.target.value)}
                            placeholder="Email ID"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            placeholder="City"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="state">State</label>
                        <select
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            required
                        >
                            <option value="">Select a state</option>
                            {states.map((state, index) => (
                                <option key={index} value={state}>{state}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            required
                        >
                            <option value="">Select a status</option>
                            {statusOptions.map((statusOption, index) => (
                                <option key={index} value={statusOption}>{statusOption}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="submit-btn">
                    <button type="submit">Add Enquiry</button>
                    <button type="button" onClick={handleSendEnquiry}>Send Enquiry</button>
                    <button type="button" onClick={clearFormFields}>Clear</button>
                </div>
                {successMessage && <div className="success-message">{successMessage}</div>}
                {errorMessage && <div className="error-message">{errorMessage}</div>}
            </form>

            {emailFormVisible && (
                <div className="email-form">
                    <h2>Send Enquiry</h2>
                    <form onSubmit={handleSendEmail}>
                    <div className="form-group">
                        <label htmlFor="enquiryID">Enquiry ID</label>
                        <select
                            value={enquiryID}
                            onChange={handleEnquirySelection}
                            required
                            >
                            <option value="">Select Enquiry ID</option>
                            {Array.isArray(enquiries) && enquiries.length > 0 ? (
                                enquiries.map((enquiry) => (
                                <option key={enquiry.enquiryID} value={enquiry.enquiryID}>
                                    {enquiry.enquiryID} - {enquiry.productType}
                                </option>
                                ))
                            ) : (
                                <option value="">No enquiries available</option>
                            )}
                            </select>
                    </div>

                        <div className="form-group">
                            <label htmlFor="emailTo">To</label>
                            <select
                                value={selectedSeller}
                                onChange={(e) => setSelectedSeller(e.target.value)}
                                required
                            >
                                <option value="">Select Seller</option>
                                {Array.isArray(sellerList) && sellerList.length > 0 ? (
                                    sellerList.map((seller, index) => (
                                        <option key={index} value={seller.email}>
                                            {seller.name} ({seller.email})
                                        </option>
                                    ))
                                ) : (
                                    <option>No sellers available</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="subject">Subject</label>
                            <input
                                type="text"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="body">Body</label>
                            <textarea
                                value={body}
                                onChange={(e) => setBody(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="attachment">Attachment</label>
                            <input
                                type="file"
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className="form-group">
                            <button type="submit">Send</button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default EnquiryForm;
