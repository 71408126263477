
import React, { useState } from 'react';
import EnquiryForm from './EnquiryForm/EnquiryForm';
import EnquiryList from './EnquiryList/EnquiryList';

const ParentComponent = () => {
    const [enquiries, setEnquiries] = useState([]);

    const handleAddEnquiry = (newEnquiry) => {
        setEnquiries((prevEnquiries) => [...prevEnquiries, newEnquiry]);
    };

    return (
        <div>
            <h1>Enquiries Management</h1>
            <EnquiryForm onAddEnquiry={handleAddEnquiry} />
            <EnquiryList/>
            {/* Render enquiries list or other components here */}
        </div>
    );
};

export default ParentComponent;
