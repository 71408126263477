import React, { useState } from 'react';
import BuyerForm from './BuyerForm/BuyerForm';
import BuyerList from './BuyerList/BuyerList';

const ParentComponent = () => {
    const [buyers, setBuyers] = useState([]);

    const handleAddBuyer = (newBuyer) => {
        setBuyers((prevBuyers) => [...prevBuyers, newBuyer]);
    };

    return (
        <div>
            <h1>Buyer Management</h1>
            <BuyerForm onAddBuyer={handleAddBuyer} />
            <BuyerList/>
            {/* Render buyers list or other components here */}
        </div>
    );
};

export default ParentComponent;
