// src/Login/LoginPage.js
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../App'; // Ensure this path is correct

const LoginPage = () => {
    const { setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogin = () => {
        setIsAuthenticated(true); // Simulate login
        navigate('/'); // Redirect to the home page after login
    };

    const handleLogout = () => {
        setIsAuthenticated(false); // Simulate logout
        localStorage.removeItem('isAuthenticated'); // Remove from local storage
        navigate('/login'); // Redirect to login page
    };

    return (
        <div>
            <h2>Login</h2>
            <input type="text" placeholder="Username" />
            <input type="password" placeholder="Password" />
            <label>
                <input type="checkbox" />
                Remember Me
            </label>
            <button onClick={handleLogin}>Log In</button>
            {/* You can add a button to simulate logout for testing */}
            <button onClick={handleLogout}>Log Out</button>
        </div>
    );
};

export default LoginPage;
